.price-grid-wrapper{
    padding: 110px 0 75px 0 ;
}
.price-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: transparent;
    gap: 30px;
    padding: 0 100px;
}
.price-card {
    background-color: var(--white);
    padding: 40px 30px ;
    border-radius: 6px;
    text-align: center;
    color: var(--purple);
    transition: all 400ms ease;
    box-shadow: 0 8px 30px rgb(0 0 0 / 6%);
}
.price-card:hover {
    transform: translateY(-5px);
}
.price-card .generic-btn-1 {
    display: inline;
}
.price-card-header h1 {
    font-weight: bold;
}
.price-card ul {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: start;
}
.price-card ul li {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: start;
}
.price-card-feature-list li span {
    margin-left: 8px;

}
.price-card:nth-child(2)
{
    border-top : 5px solid var(--blue); 
    transform: translateY(-20px);
    background-color: var(--purple);
    color: var(--white);
}
.price-card:nth-child(2):hover .generic-btn-1 {
    background-color: white;
    color: var(--purple);
}

@media ( min-width : 992px ) and ( max-width : 1199.8px )
{
    .price-grid {
        padding: 0;
    }
}

@media ( min-width : 768px ) and ( max-width : 991.8px )
{
    .price-grid {
        padding: 0;
        grid-template-columns: 1fr 1fr  ;

    }
    .price-grid .price-card:nth-child(2)
    {
        grid-column: 1/3;
        order: -1;
    }
}
@media ( min-width : 576px ) and ( max-width : 767.8px )
{
    .price-grid {
        padding: 0;
        grid-template-columns: 1fr 1fr  ;

    }
    .price-grid .price-card:nth-child(2)
    {
        grid-column: 1/3;
        order: -1;
    }
}

@media ( max-width : 576px )
{
    .price-grid {
        padding: 0;
        grid-template-columns: 1fr  ;

    }   
    .price-card {
        padding: 20px 20px 40px 20px;
    }
    .price-grid .price-card:nth-child(2)
    {
        order: -1;
    }
}