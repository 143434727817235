.success-stories {
    background-color: var(--purple) ;
}
.video-slider{
    position: relative;
}
.video-item-wrapper{
    padding: 0 10px;
}
.video-item-wrapper > div {
    width: auto !important;
    height: auto !important; 
    aspect-ratio: 98 / 55 !important;
}
.video-slider-wrapper .slick-slider :is( .slick-prev , .slick-next ) {
    position: absolute;
    bottom : 50%;
    top: unset;
    background-color: transparent;
    width: 50px;
    height: 50px;
    border: 0;
    border-radius: 5px;
    outline: none !important;
    opacity: 0.9;
    transition: all 300ms ease;
    z-index: 10;
}
.video-slider-wrapper .slick-slider :is( .slick-prev , .slick-next )::before{
    display: none;
}
.video-slider-wrapper .slick-slider :is( .slick-prev , .slick-next ) img {
    width: 40px;
}
.video-slider-wrapper .slick-slider :is( .slick-prev , .slick-next ):hover{
    opacity: 1;
}
.video-slider-wrapper .slick-slider .slick-prev {
    left: 0%;
    transform: translateX(-0%) ;
}
.video-slider-wrapper .slick-slider .slick-next {
    right: 0%;
    transform: translateX(0%);
}

@media ( min-width : 992px ) and ( max-width : 1199.8px ) {
    .video-slider-item-inner {
        padding: 0 20px;
  
    }
    .video-slider-item-inner > div {
        height: 100%;
    }
}

@media ( min-width : 768px ) and ( max-width : 991.8px ) {
    .video-slider-item-inner {
        padding: 0 20px;
    }
    .video-slider-item-inner > div {
        height: 100%;
    }
    .video-slider-wrapper .slick-slider .slick-prev {
        left: 50%;
        bottom: -20px;
        transform: translate( -90% , 100%) ;
    }
    .video-slider-wrapper .slick-slider .slick-next {
        right: 50%;
        bottom: -20px;
        transform: translate( 90% , 100%) ;
    }
}

@media ( min-width : 576px ) and ( max-width : 767.8px ) {
    .success-stories {
        padding-bottom: 100px !important    ;
    }
    .video-slider-item-inner {
        padding: 0 20px;
    }

    .video-slider-wrapper .slick-slider .slick-prev {
        left: 50%;
        bottom: -20px;
        top: unset;
        transform: translate( -90% , 100%) ;
    }
    .video-slider-wrapper .slick-slider .slick-next {
        right: 50%;
        top: unset;
        bottom: -20px;
        transform: translate( 90% , 100%) ;
    }
}

@media  ( max-width : 575.8px ) {
    .success-stories {
        padding-bottom: 100px !important    ;
    }
    .video-slider-item-inner {
        padding: 0 00px;
    }
    .video-slider-wrapper .slick-slider .slick-prev {
        left: 50%;
        top: unset;
        bottom: -20px;
        transform: translate( -90% , 100%) ;
    }
    .video-slider-wrapper .slick-slider .slick-next {
        right: 50%;
        top: unset;
        bottom: -20px;
        transform: translate( 90% , 100%) ;
    }
    .video-slider-wrapper .slick-slider :is( .slick-prev , .slick-next ) img {
        width: 27px;
    }
}
