.success-page{
    background-color: var(--blue);
    color: white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.success-page a {
    color: white ;
    text-decoration: underline !important;
    font-size: 20px;
}